
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UpdateDisabledButton extends Vue {
  @Prop({ required: true })
  isDisabled!: boolean;

  @Prop({ required: true })
  loading!: boolean;
}
